import {isDefined, set} from "@vueuse/core";
import {RANGE, GROUP_RANGE, GROUP_BY, HADOOP} from "~/const/ANALYTICS";
import {useProgressIndicatorStore} from "~/stores/useProgressIndicatorStore";
import {useSitesStore} from "~/stores/useSitesStore";
import {EXO_CLICK_ID, HILTOP, OCTO_CLICK, PUB_ADS_ID, TRAFFIC_STARTS_ID} from "~/const/ADVERTISER";

interface RuntimeConfig {
  public: {
    apiUrl: string;
  };
}
export const useAnalyticsStore = defineStore('analyticsStore', () => {
  const authStore = useAuthStore();
  const config: RuntimeConfig = useRuntimeConfig();
  const report: Ref<[] | null> = ref([]);
  const initialReport: Ref<[] | null> = ref([]);
  const shortInfo = ref({});
  const { username,token } = authStore;

  const getFirstReport = computed(() => {
    const { Date, ...rest } = toValue(report)[0] ?? {};
    return rest;
  });

  const getTotalParameters = computed(() => toValue(report).reduce((acc, item) => {
    acc.Clicks += (item.Clicks ?? 0);
    acc.Profit += parseFloat(item.Profit) || 0;
    return acc;
  }, { Clicks: 0, Profit: 0 }))

  interface GenerateReportParams {
    range?: RangeKey;
    groupRange?: GroupRangeKey,
    groupBy?: [GroupBy] | GroupBy[''],
    country?: object,
    customFrom?: string,
    customTo?: string,
    placement: string,
    adviser: string,
  }


  const generateReport = async ({
     range = RANGE.TODAY,
     groupRange = GROUP_RANGE.NONE,
     groupBy = GROUP_BY[''],
     customFrom = '',
     country = {},
     customTo = '',
     placement = '',
     adviser = ''
   }: GenerateReportParams) => {
    const progressStore = useProgressIndicatorStore();

    progressStore.updateStatus(true);
    let reportRef = null;
    switch (adviser) {
      case PUB_ADS_ID:
        reportRef = await getReportPubAds({
          range,
          groupRange,
          groupBy,
          customFrom,
          country: country.code,
          customTo,
          placement,
          adviser
        })
        break;
      case EXO_CLICK_ID:
        reportRef =  await getReportExoclick({
          customFrom,
          country: country.iso3,
          customTo,
          placement,
        });
        break;
      case OCTO_CLICK:
        reportRef = await getReportOctoClick({
          customFrom,
          country: country.value,
          customTo,
          placement,
        });
        break;
      case HILTOP:
        reportRef = await getReportHiltop({
          customFrom,
          country: country.code,
          customTo,
          placement,
        });
        break;
      case TRAFFIC_STARTS_ID:
        reportRef = await getReportTrafficStarts({
          customFrom,
          country: country.code,
          customTo,
          placement,
        });
        break;


      default:
        reportRef = await getReportPubAds({
          range,
          groupRange,
          groupBy,
          customFrom,
          country: country.code,
          customTo,
          placement,
          adviser
        })
        break;
    }

    set(report, reportRef);
    progressStore.updateStatus(false);
  };

  async function getReportPubAds({
                                   range = RANGE.TODAY,
                                   groupRange = GROUP_RANGE.NONE,
                                   groupBy = GROUP_BY[''],
                                   customFrom = '',
                                   country = '',
                                   customTo = '',
                                   placement = '',
                                   adviser = ''
                                 }) {
    const siteStore = useSitesStore();
    if (siteStore.siteId === null) {
      await siteStore.updateListSites();
    }
    const data =
      await $fetch('/api/statistics/report', {
        method: 'GET',
        query: {
          ...Object.fromEntries(
            Object.entries({
              range,
              groupRange,
              groupBy,
              customFrom,
              customTo,
            }).filter(([, value]) => value)),
          username,
          token,
          siteId: toValue(siteStore.siteId),
          ...(country?.length && { country }),
          ...(placement?.length && { placement }),
          ...(adviser?.length && { adviser }),
        },
      });
    const getReportPreForUser = toValue(data).map((item) => ({
      'Date': item.Date,
      'Impressions': item.Impressions,
      'Clicks': item.Clicks,
      'CTR': item.CTR.toFixed(2),
      Request: item.Requests.toFixed(2),
      'Fill Rate': item['Fill Rate'],
      eCPM: item.eCPM.toFixed(2),
      Profit: item.Net.toFixed(2),
    }))

    return getReportPreForUser.reverse();
  }

  async function getReportHiltop( {
                                     customFrom = '',
                                     country = '',
                                     customTo = '',
                                     placement = ''
                                   } ) {
    const data =
      await $fetch('https://hilltopads.com/api/publisher/listStats', {
        query: {
          ...(country?.length && { 'geo': country }),
          key: config.public.token.hiltop,
          ...(customFrom?.length && { date: customFrom}),
          ...(customTo?.length && { date2: customTo }),
          group: 'date'
        }
      });

    const result = toValue(data).result;

    const convertData = Object.entries(result).map(([date, value]) => ({
      date,
      ...value[0]
    })).map((item) => ({
      'Date': item.date,
      'Impressions': item.impressions,
      'Clicks': item.clicks,
      'CTR': item.CTR?.toFixed(2),
      Request: item.Requests?.toFixed(2),
      'Fill Rate': item['Fill Rate'],
      eCPM: item.cpm,
      Profit: parseFloat(item.revenue).toFixed(2),
    })).reverse()

    return convertData;
  }

  async function getReportTrafficStarts({
                                     customFrom = '',
                                     country = '',
                                     customTo = '',
                                     placement = ''
                                   }) {
    if (!isDefined(toValue(authStore.trafficStarsUser).access_token)) {
      await authStore.loginTrafficStars();
    }

    const data = await $fetch('https://api.trafficstars.com/v1.1/publisher/custom/report/by-day', {
      headers: {
        Authorization: `Bearer ${toValue(authStore.trafficStarsUser).access_token}`
      },
      query: {
        ...(country?.length && { 'country_code': country }),
        ...(customFrom?.length && { 'date_from': customFrom}),
        ...(customTo?.length && { 'date_to': customTo }),
      }
    });
    const convertData = data.map((item) => ({
      'Date': item.day,
      'Impressions': item.impressions,
      'Clicks': item.clicks,
      'CTR': item.CTR?.toFixed(2),
      Request: item.Requests?.toFixed(2),
      'Fill Rate': item['Fill Rate'],
      eCPM: item.ecpm?.toFixed(2),
      Profit: item.revenue?.toFixed(2),
    })).reverse();
    return convertData;
  }

  async function getReportExoclick({
                                     customFrom = '',
                                     country = '',
                                     customTo = '',
                                     placement = ''
                                   }) {
    if (!isDefined(toValue(authStore.exoClickUser).token)) {
      await authStore.loginExoClick();
    }

    const data =  await $fetch('https://api.exoclick.com/v2/statistics/p/date', {
      headers: {
        Authorization: `Bearer ${toValue(authStore.exoClickUser).token}`
      },
      query: {
        adblock: 'all',
        limit: 5000,
        ...(country?.length && { 'country': country }),
        ...(customFrom?.length && { 'date-from': customFrom}),
        ...(customTo?.length && { 'date-to': customTo }),
      }
    });
    const convertData = data.result.map((item) => ({
      'Date': item.ddate,
      'Impressions': item.impressions,
      'Clicks': item.clicks,
      'CTR': item.CTR?.toFixed(2),
      Request: item.Requests?.toFixed(2),
      'Fill Rate': item['Fill Rate'],
      eCPM: item.cpm?.toFixed(2),
      Profit: item.revenue?.toFixed(2),
    })).reverse();

    return convertData;
  }

  async function getReportOctoClick( {
                                    customFrom = '',
                                    country = null,
                                    customTo = '',
                                    placement = ''
                                  } ) {
    const where = isDefined(country) ? [
      {
        "field": "Country",
        "operator": "=",
        "value": [
          country
        ]
      },
    ] : [];
    const raw = JSON.stringify({
      "date_from": `${customFrom} 00:00:00`,
      "date_to": `${customTo} 00:00:00`,
      "group_by": [ "EventDate"],
      where,
      metrics: ['cpcW', 'cpmWM', 'Ctr', 'Click', 'Impression', 'WebmasterProfit', 'uniqCpmWM', 'uniqClick', 'uniqCtr', 'uniqImpression'],
      "datetime_range": "day"
    });
    const data =
      await $fetch('https://api.octoclick.com/api/v4/statistic/table', {
        method: 'POST',
        headers: {
          Authorization: `ApiToken ${config.public.token.octoclick}`,
          'Content-Type': 'application/json',
        },
        body: raw,
        query: {
          'page[size]': 100,
        }
      });

    const result = data.data.map(item => ({
      date: item.group.EventDate.id,
      ...item.metric
    }));

    const convertData = result.map((item) => ({
      'Date': item.date,
      'Impressions': item.Impression,
      'Clicks': item.Click,
      'CTR': item.Ctr?.toFixed(2),
      Request: item.Requests?.toFixed(2),
      'Fill Rate': item['Fill Rate'],
      eCPM: item.cpmWM?.toFixed(2),
      Profit: item.WebmasterProfit?.toFixed(2),
    })).reverse()

    return convertData
  }

  function setShortInfo(info) {
    set(shortInfo, info);
  }

  return {
    report,
    initialReport,
    getFirstReport,
    generateReport,
    getReportPubAds,
    getReportHiltop,
    getReportExoclick,
    getReportOctoClick,
    getReportTrafficStarts,
    setShortInfo,
    getTotalParameters,
  }
})
